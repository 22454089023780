/* eslint-disable import/extensions */
window.commonSeriveUrl =
  window.location.protocol === "http:"
    ? `http://${window.location.hostname}:3001/webcommon`
    : `${window.location.origin}/webcommon`;
window.appraisalDashboardServiceUrl =
  window.location.protocol === "http:"
    ? `http://${window.location.hostname}:3004`
    : `${window.location.origin}/appraisal_dashboard`;
import("./bootstrap.jsx");
